@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@200;300;400;500;600&display=swap)

$fa-font-path: '../../public_html/dist/fonts'

@import '@fortawesome/fontawesome-free/scss/variables'
@import '@fortawesome/fontawesome-free/scss/mixins'
@import '@fortawesome/fontawesome-free/scss/core'
@import '@fortawesome/fontawesome-free/scss/larger'
@import '@fortawesome/fontawesome-free/scss/fixed-width'
@import '@fortawesome/fontawesome-free/scss/list'
@import '@fortawesome/fontawesome-free/scss/bordered-pulled'
@import '@fortawesome/fontawesome-free/scss/animated'
@import '@fortawesome/fontawesome-free/scss/stacked'
@import '@fortawesome/fontawesome-free/scss/regular'
@import '@fortawesome/fontawesome-free/scss/solid'
@import '@fortawesome/fontawesome-free/scss/brands'

@import '../../node_modules/bootstrap/scss/functions'

$gray: #555

$font-family-sans-serif: Raleway, system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"

$lead-font-size: 1.4rem

@import '../../node_modules/bootstrap/scss/variables'

$theme-colors: ("primary":$primary, "secondary":$secondary, "success":$success, "info":$info, "warning":$warning, "danger":$danger, "light":$light, "dark":$dark, "gray":$gray)

$nav-link-color: $gray-500
$nav-link-hover-color: $gray-300

$offcanvas-bg-color: $gray-900
$offcanvas-color: $gray-100

$card-border-color: rgba($black, .120)

@import '../../node_modules/bootstrap/scss/mixins'
@import '../../node_modules/bootstrap/scss/utilities'
@import '../../node_modules/bootstrap/scss/root'
@import '../../node_modules/bootstrap/scss/reboot'
@import '../../node_modules/bootstrap/scss/type'
@import '../../node_modules/bootstrap/scss/images'
@import '../../node_modules/bootstrap/scss/containers'
@import '../../node_modules/bootstrap/scss/grid'
@import '../../node_modules/bootstrap/scss/forms'
@import '../../node_modules/bootstrap/scss/buttons'
@import '../../node_modules/bootstrap/scss/transitions'
@import '../../node_modules/bootstrap/scss/nav'
@import '../../node_modules/bootstrap/scss/card'
@import '../../node_modules/bootstrap/scss/alert'
@import '../../node_modules/bootstrap/scss/close'
@import '../../node_modules/bootstrap/scss/offcanvas'
@import '../../node_modules/bootstrap/scss/helpers'
@import '../../node_modules/bootstrap/scss/utilities/api'

.#{$fa-css-prefix}-bars
  &::before
    content: fa-content($fa-var-bars)
    color: $gray-600

.#{$fa-css-prefix}-buffer
  &::before
    content: fa-content($fa-var-buffer)
    color: $red

.#{$fa-css-prefix}-folder
  &::before
    content: fa-content($fa-var-folder)
    color: $red

.#{$fa-css-prefix}-code
  &::before
    content: fa-content($fa-var-code)
    color: $red

.#{$fa-css-prefix}-boxes
  &::before
    content: fa-content($fa-var-boxes)
    color: $red

.#{$fa-css-prefix}-facebook-f
  &::before
    content: fa-content($fa-var-facebook-f)
    color: $white

.#{$fa-css-prefix}-linkedin-in
  &::before
    content: fa-content($fa-var-linkedin-in)
    color: $white

header
  z-index: 100

  .gwa-logo
    height: 70px
    max-width: 100%

main
  padding-top: 3.45rem

  &::before
    content: ''
    background-image: url(../../public_html/dist/img/architechture.jpg)
    background-repeat: no-repeat
    background-size: cover
    background-attachment: fixed
    display: block
    position: fixed
    left: 0
    top: 0
    width: 100%
    height: 100%
    opacity: 0.05
    z-index: -1

.intro
  background-image: url(../../public_html/dist/img/arial-view-hongkong-1920w.jpg)
  background-position: center
  background-repeat: no-repeat
  background-size: cover
  height: auto
  overflow: hidden

  &::before,
  &::after
    content: ''
    display: block
    height: 100%
    width: 100%
    position: absolute
    top: 0
    left: 0
    z-index: 1

  &::before
    background: $dark
    opacity: 0.45

  &::after
    background: linear-gradient(135deg, #ce1b28 0%, $dark 74%)
    opacity: 0.6

  > div
    z-index: 2

  video
    transform: translateX(50%) translateY(50%)
    position: absolute
    height: auto
    width: auto
    min-height: 100%
    min-width: 100%
    overflow: hidden
    right: 50%
    bottom: 50%

.about
  background-color: $red
  background-image: linear-gradient(rgba(206, 27, 40, 0.25), rgba(206, 27, 40, 0.25)), url(../../public_html/dist/img/skyscraper-red.jpg)
  background-position: bottom
  background-repeat: no-repeat
  background-size: cover

.contact
  height: 15rem
  min-height: 15rem
  background: linear-gradient(rgba(17, 17, 17, 0.25), rgba(17, 17, 17, 0.25)), url(../../public_html/dist/img/arial-view-hongkong-1920w.jpg)
  background-position: center
  background-repeat: no-repeat
  background-size: cover
  overflow: hidden

  &::before
    content: ''
    display: block
    height: 100%
    width: 100%
    position: absolute
    top: 0
    left: 0
    z-index: 1
    background: linear-gradient(135deg, #ce1b28 0%, $dark 74%)
    opacity: 0.6

  > div
    z-index: 2


.footer
  .list-unstyled
    .icon
      border-radius: 4px
      margin-right: 0.5rem
      text-align: center
      width: 2rem
      height: 2rem
      line-height: 2rem
      background: $black

textarea.form-control
  min-height: 15rem

.shadow
  text-shadow: 0 2px 3px rgb(0 0 0 / 70%)

#additional-info
  display: none

@include media-breakpoint-up(md)
  .intro
    height: 35rem

